import authHeader from './auth-header';
import axios from "axios";
import { API_MEDIA_URL } from "./config";
import qs from 'qs';

// API to read all media by entity Id
const readMedia = (entityId, params) => {
    return axios.get(API_MEDIA_URL(entityId) + '?' + qs.stringify(params), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to delete media
const deleteMedia = (entityId, mediaId) => {
    return axios.delete(API_MEDIA_URL(entityId) + '/' + mediaId, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to count all media by entity Id
const countMedia = (entityId, params) => {
    return axios.get(API_MEDIA_URL(entityId) + 'count?' + qs.stringify(params), {
        headers: authHeader()
    }).then(res => res.data);
}

const media = {
    readMedia,
    countMedia,
    deleteMedia
}

export default media;