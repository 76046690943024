import authHeader from './auth-header';
import axios from "axios";
import { API_USER_MEDIA_URL } from "./config";
import qs from 'qs';

// API to read all user-media by entity Id
const readUserMedia = (entityId, params) => {
    return axios.get(API_USER_MEDIA_URL(entityId) + '?' + qs.stringify(params), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to count all user-media by entity Id
const countUserMedia = (entityId, params) => {
    return axios.get(API_USER_MEDIA_URL(entityId) + 'count?' + qs.stringify(params), {
        headers: authHeader()
    }).then(res => res.data);
}

const userMedia = {
    readUserMedia,
    countUserMedia
}

export default userMedia;