import MediaService from "../services/media.service";

// Action to get all media
export const readMedia = (entityId, params) => (dispatch) => {
    return MediaService.readMedia(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete media
export const deleteMedia = (entityId, mediaId) => (dispatch) => {
    return MediaService.deleteMedia(entityId, mediaId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}


// Action to count all media
export const countMedia = (entityId, params) => (dispatch) => {
    return MediaService.countMedia(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}