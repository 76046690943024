// Add API workable filter form + pagination on a table listing page

import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, message, Row, Select, Space } from 'antd';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

function buildFormField(getterSetter, formField) {
    if (formField.needGetterSetter === true && formField.type === 'select') {
        return <Form.Item label={formField.label} name={formField.name}>
            <Select allowClear placeholder={formField.label} options={getterSetter.options[formField.name]} />
        </Form.Item>
    } else {
        return <Form.Item label={formField.label} name={formField.name}>
            {formField.formItem}
        </Form.Item>
    }
}

function buildFilterForm(getterSetter, formFields, onFinishFilterAction, onFinishFailedFilterAction, resetFilterForm, filterForm) {
    return <Form onFinish={onFinishFilterAction} onFinishFailed={onFinishFailedFilterAction} form={filterForm} layout="vertical" style={{ width: '100%' }}>
        <Row gutter={[16, 0]} style={{ width: '100%' }}>
            {formFields.map((formField) => {
                return <Col key={formField.name} span={8}>
                    {buildFormField(getterSetter, formField)}
                </Col>
            })}
            <Col span={24}>
                {/* right align buttons */}
                <div style={{ textAlign: 'right' }}>
                    <Space>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button type="primary" danger onClick={resetFilterForm}>Reset</Button>
                    </Space>
                </div>
            </Col>
        </Row>
    </Form>
}

function defaultOnFinishFilterAction(formRef, searchParams, setSearchParams, page, pageSize) {
    return (values) => {
        let filteredValues = {};
        for (let key in values) {
            // check if the value is an object of dayjs
            if (values[key] instanceof dayjs) {
                filteredValues[key] = values[key].toISOString();
            } else if (typeof values[key] !== 'undefined' && values[key] && values[key].trim() !== '') {
                filteredValues[key] = values[key].toString();
            }
        }
        setSearchParams({ ...filteredValues, page: page.toString(), pageSize: pageSize.toString() });
    }
}

function defaultOnFinishFailedFilterAction(formRef, searchParams, setSearchParams, page, pageSize) {
    return (errorInfo) => {
        try {
            console.log('Failed:', errorInfo);
            message.error(errorInfo.errorFields[0].errors[0]);
        } catch (e) {
            if (e instanceof Error) {
                console.log('Failed:', e.message);
                message.error(e.message);
            } else {
                console.log(e);
            }
        }
    }
}

function defaultResetFilterForm(formRef, searchParams, setSearchParams, page, pageSize) {
    return () => {
        formRef.resetFields();
        setSearchParams({
            page: page.toString(),
            pageSize: pageSize.toString()
        });
    }
}

const defaultPageSize = 100;

const withFilterForm = (
    Component,
    formFields,
    onFinishFilterAction,
    onFinishFailedFilterAction,
    resetFilterForm,
    defaultPageSize,
    defaultPage,
    params
) => {
    return (props) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page')) : (defaultPage ? defaultPage : 1));
        const finalDefaultPageSize = defaultPageSize ? defaultPageSize : 100;
        const [pageSize, setPageSize] = useState(searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : finalDefaultPageSize);
        const [formRef] = Form.useForm();
        const finalOnFinishFilterAction = onFinishFilterAction ? onFinishFilterAction : defaultOnFinishFilterAction;
        const finalOnFinishFailedFilterAction = onFinishFailedFilterAction ? onFinishFailedFilterAction : defaultOnFinishFailedFilterAction;
        const finalResetFilterForm = resetFilterForm ? resetFilterForm : defaultResetFilterForm;
        const [options, setOptions] = useState([]);
        const getterSetter = {
            options: options,
            setOptions: setOptions
        }

        useEffect(() => {
            params.forEach((param) => {
                let currentFormField = formFields.find((formField) => formField.name === param);
                if (typeof currentFormField !== 'undefined' && currentFormField.type === 'date') {
                    // Parse the string to dayjs
                    let date = dayjs(searchParams.get(param));
                    if (date.isValid()) {
                        formRef.setFieldValue(param, date);
                    }
                } else {
                    if (typeof searchParams.get(param) !== 'undefined' && searchParams.get(param) && searchParams.get(param) !== '') {
                        formRef.setFieldValue(param, searchParams.get(param));
                    }
                }
            });
        }, [formRef]);

        useEffect(() => {
            let formValues = formRef.getFieldsValue();
            let filteredValues = {};
            for (let key in formValues) {
                // If the value is an instance of dayjs, convert it to ISO string
                if (formValues[key] instanceof dayjs) {
                    filteredValues[key] = formValues[key].toISOString();
                } else if (typeof formValues[key] !== 'undefined') {
                    filteredValues[key] = formValues[key].toString();
                }
            }
            setSearchParams({ ...filteredValues, page: page.toString(), pageSize: pageSize.toString() });
        }, [page, pageSize]);

        return <Component {...props}
            options={options}
            setOptions={setOptions}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            formRef={formRef}
            form={buildFilterForm(getterSetter, formFields, finalOnFinishFilterAction(formRef, searchParams, setSearchParams, page, pageSize), finalOnFinishFailedFilterAction(formRef, searchParams, setSearchParams, page, pageSize), finalResetFilterForm(formRef, searchParams, setSearchParams, page, pageSize), formRef)} />;
    };
};

export { withFilterForm, defaultPageSize };