import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Input, message, Button, Table, Space, Upload, Form, Col, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { CopyOutlined, InboxOutlined, RedoOutlined } from '@ant-design/icons';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { readUserMedia, countUserMedia } from '../actions/user-media';
import { API_CDN_URL, API_USER_MEDIA_URL } from '../services/config';
import authHeader from '../services/auth-header';
import moment from 'moment';
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import { getShortToken } from '../actions/auth';
import { defaultPageSize, withFilterForm } from '../components/withFilterForm';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

function EntityUserMedia(props) {
    const { form, searchParams, setPage, setPageSize } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const params = useParams();
    const currentEntityId = params.entityId ? params.entityId : props.entityId;
    const userMediaUploadAction = API_USER_MEDIA_URL(currentEntityId) + 'upload';
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === currentEntityId) : null;
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const [processedUploadedFiles, setProcessedUploadedFiles] = useState([]);
    const [shortToken, setShortToken] = useState(null);

    const userMediaUploadChange = (event) => {
        if (Array.isArray(event.fileList)) {
            event.fileList.forEach(uploadedFile => {
                console.log(uploadedFile);
                if (uploadedFile.status === 'error' || uploadedFile.status === 'done') {
                    if (processedUploadedFiles.indexOf(uploadedFile.name) === -1) {
                        setProcessedUploadedFiles([...processedUploadedFiles, uploadedFile.name]);
                        if (uploadedFile.status === 'done') {
                            message.success(uploadedFile.response.message);
                        } else {
                            message.error(uploadedFile.response.message);
                        }
                    }
                }
            });
        }
    }


    const columns = [
        {
            title: t('user-media.columns.thumbnail'),
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            render: (text, record) => {
                if (currentEntity) {
                    let link = API_USER_MEDIA_URL(record.entity) + record.id + '?token=' + (shortToken ? shortToken : '');
                    return <a href={link} rel="noreferrer" target="_blank"><img src={link} alt={record.meta.originalname} style={{ width: 80, height: 80, objectFit: 'contain' }} /></a>;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('user-media.columns.id'),
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('user-media.columns.user'),
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return record.user ? <Link to={`/entity/${params.entityId}/user/${record.user._id}`}>{record.user.lastName}, {record.user.firstName}</Link> : 'N/A';
            }
        },
        {
            title: t('user-media.columns.fromAdmin'),
            dataIndex: 'fromAdmin',
            key: 'fromAdmin',
            render: (text, record) => {
                if (typeof record.fromAdmin !== 'undefined' && record.fromAdmin === true) {
                    return t('user-media.columns.yes');
                } else {
                    return t('user-media.columns.no');

                }
            }
        },
        {
            title: t('user-media.columns.originalname'),
            dataIndex: 'meta.originalname',
            key: 'meta.originalname',
            render: (text, record) => {
                return record.meta ? record.meta.originalname : 'N/A';
            }
        },
        {
            title: t('user-media.columns.path'),
            dataIndex: 'path',
            key: 'path',
            render: (text, record) => {
                return record.id + '/' + record.path
            }
        },
        {
            title: t('user-media.columns.link'),
            key: 'link',
            render: (text, record) => {
                if (currentEntity) {
                    let link = API_USER_MEDIA_URL(record.entity) + record.id + '?token=' + (shortToken ? shortToken : '');
                    return <a href={link} rel="noreferrer" target="_blank">Open</a>;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('user-media.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                return moment(record['createdAt']).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: t('user-media.columns.actions'),
            key: 'action',
            render: (text, record) => {
                return <Space>
                    {props.entityId && <Button size="small" type="primary" onClick={() => props.selectSelectMedia ? props.selectUserMedia(record) : null}>{t('user-media.actions.select')}</Button>}
                    <Button size="small" type="primary" onClick={() => copyUserMediaUrl(record)}><CopyOutlined /> {t('user-media.actions.copy')}</Button>
                    {/* <Button size="small" type="primary" onClick={() => copyUserMediaId(record)}><CopyOutlined /> {t('user-media.actions.copy-id')}</Button> */}
                </Space>
            }
        }
    ];

    const copyUserMediaUrl = (record) => {
        let url = record.id + '/' + record.path;
        navigator.clipboard.writeText(url);
        message.success(t('user-media.message.copied'));
    }

    const reloadUserMedia = useCallback(() => {
        const filters = {};
        if (typeof searchParams.get('id') !== 'undefined' && searchParams.get('id') && searchParams.get('id') !== '') {
            filters['id'] = searchParams.get('id');
        }
        if (typeof searchParams.get('user') !== 'undefined' && searchParams.get('user') && searchParams.get('user') !== '') {
            filters['user'] = searchParams.get('user');
        }
        if (typeof searchParams.get('originalname') !== 'undefined' && searchParams.get('originalname') && searchParams.get('originalname') !== '') {
            filters['originalname'] = searchParams.get('originalname');
        }
        const filtersWithPagination = { ...filters, page: searchParams.get('page'), pageSize: searchParams.get('pageSize') };
        dispatch(getShortToken()).then((data) => {
            if (data && data.shortToken) {
                setShortToken(data.shortToken);
            }
        }).catch(e => {
            console.log(e.message);
        });
        dispatch(countUserMedia(currentEntityId, filters)).then(function (data) {
            setTotal(data.result);
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readUserMedia(currentEntityId, filtersWithPagination)).then((data) => {
            setDataSource(data.result);
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, currentEntityId, searchParams]);

    useEffect(() => {
        reloadUserMedia();
    }, [reloadUserMedia]);

    const headerBack = () => navigate('/');
    const innerUserMediaLibrary = <><PageHeader
        className="site-page-header"
        onBack={props.entityId ? null : headerBack}
        title={t('user-media.title')}
        subTitle={t('user-media.subtitle')}
        extra={[
            <Button key="refresh" type="primary" onClick={() => reloadUserMedia()}>
                <RedoOutlined /> {t('user-media.actions.refresh')}</Button>
        ]}
    />
        {!userIsReadOnly && <div style={{ marginBottom: 20 }}>
            <Upload.Dragger
                name="files"
                headers={authHeader()}
                action={userMediaUploadAction}
                onChange={userMediaUploadChange} multiple={true}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('user-media.upload.action-reminder')}</p>
                <p className="ant-upload-hint">{t('user-media.upload.support-reminder')}</p>
            </Upload.Dragger>
        </div>}
        {!userIsReadOnly && <h4>{t('user-media.message.refresh-reminder')}</h4>}
        {form}
        <Table
            size='small'
            rowKey={record => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={{
                total: total,
                onChange(page, pageSize) {
                    setPage(page);
                    setPageSize(pageSize);
                },
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                defaultCurrent: searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
                defaultPageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : defaultPageSize,
                showSizeChanger: true,
                pageSizeOptions: [defaultPageSize.toString(), '250', '500', '1000'],
                position: ['topRight', 'bottomRight']
            }}
        /></>;

    if (props.entityId) {
        // Means called in drawer
        return innerUserMediaLibrary;
    } else {
        return <>
            <MemberSidebar />
            <Layout className="site-layout">
                <Content style={{ margin: '0 16px' }}>
                    <div className="site-layout-background">
                        {innerUserMediaLibrary}
                    </div>
                </Content>
                <MemberFooter />
            </Layout>
        </>;
    }
}

export default withFilterForm(EntityUserMedia, [
    { name: 'id', label: 'ID', formItem: <Input placeholder='ID' /> },
    { name: 'user', label: 'User', formItem: <Input placeholder='User' /> },
    { name: 'originalname', label: 'Original Name', formItem: <Input placeholder='Original Name' /> },
], null, null, null, defaultPageSize, 1, ['id', 'user', 'originalname']);